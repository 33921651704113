<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('warehouse_report.productStockReport') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
          <b-row>
            <b-col sm="4">
              <ValidationProvider name="Farmer" vid="farmer_id" rules="required|min_value:1">
                <b-form-group
                class="row"
                label-cols-sm="3"
                label-for="farmer_id"
                :label="$t('warehouse_report.farmer')"
                slot-scope="{ valid, errors }"
                >
                <b-form-select
                    plain
                    v-model="search.farmer_id"
                    :options="farmerListEn"
                    id="farmer_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    v-if="currentLocale === 'en'"
                    >
                </b-form-select>
                <b-form-select
                    plain
                    v-model="search.farmer_id"
                    :options="farmerListBn"
                    id="farmer_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    v-else
                >
                </b-form-select>
<!--                <b-form-select-->
<!--                    plain-->
<!--                    v-model="search.farmer_id"-->
<!--                    :options="farmerDropDownList"-->
<!--                    id="farmer_id"-->
<!--                    v-else-->
<!--                >-->
<!--                </b-form-select>-->
                    <div v-if="!search.farmer_id" class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-for="crop_type_id"
                  :label="$t('warehouse_report.crop_type')"
              >
                <b-form-select
                    plain
                    v-model="search.crop_type_id"
                    :options="cropTypeDropdownList"
                    id="crop_type_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="crop_id"
                  :label="$t('warehouse_report.crop_name')"
              >
                <b-form-select
                    plain
                    v-model="search.crop_id"
                    :options="cropNameDropdownList"
                    id="crop_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col class="text-right">
                <b-button type="submit" variant="primary">{{ $t('globalTrans.submit')}}</b-button>
            </b-col>
          </b-row>
          </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_report.productStockReport') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button variant="primary" @click="pdfExport" class="mr-2">
                {{  $t('globalTrans.export_pdf') }}
                </b-button>
                <export-excel
                    class="btn btn-success mr-2"
                    :data="dataCustomizeExcel"
                    :fields="json_fields"
                    :title="$t('warehouse_report.productStockReport')"
                    worksheet="Product Stock Report Sheet"
                    name="warehouse-info.xls">
                    {{ $t('globalTrans.export_excel') }}
                </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
                <b-row v-show="!loading">
                   <b-col>
                        <list-report-head :base-url="warehouseServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id">
                          {{ $t('warehouse_report.productStockReport')}}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
              <b-row mt-5>
                <b-col md="12" class="table-responsive">
                  <div>
                      <b-table-simple hover small caption-top responsive>
                        <b-thead>
                          <b-tr>
                            <b-th style="width:5%">{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th style="width:15%">{{ $t('warehouse_report.crop_type') }}</b-th>
                            <b-th style="width:15%">{{ $t('warehouse_report.crop_name') }}</b-th>
                            <b-th class="text-right" style="width:15%">{{ $t('warehouse_report.stockQty') }}</b-th>
                            <b-th class="text-right" style="width:15%">{{ $t('warehouse_report.releaseQty') }}</b-th>
                            <b-th class="text-right" style="width:15%">{{ $t('warehouse_report.availableQty') }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                           <template v-for="(cropTypeInfo, cropTypeId, cropTypeIndex) in wareReportData">
                             <template v-for="(cropNameInfo, cropNameId, indx) in cropTypeInfo" >
                                  <b-tr :key="cropTypeIndex + indx + 200" v-if="indx">
                                    <b-th v-if="indx"><span class="capitalize">{{ typeof cropNameList.find(item => item.value == cropNameId) != 'undefined' ? (currentLocale === 'en' ? cropNameList.find(item => item.value == cropNameId).text : cropNameList.find(item => item.value == cropNameId).text_bn) : '' }}</span></b-th>
                                    <b-td v-if="indx" class="text-right">{{ $n(cropNameInfo.stock_qty) }}</b-td>
                                    <b-td v-if="indx" class="text-right">{{ $n(cropNameInfo.release_qty) }}</b-td>
                                    <b-td v-if="indx" class="text-right">{{ $n(cropNameInfo.available_qty) }}</b-td>
                                  </b-tr>
                                  <b-tr :key="cropTypeIndex + indx + 999" v-else class="dfs">
                                    <b-th :rowspan="Object.keys(cropTypeInfo).length+1">{{ cropTypeIndex+1 }}</b-th>
                                    <b-th :rowspan="Object.keys(cropTypeInfo).length+1"><span class="capitalize">{{ typeof cropTypeList.find(item => item.value == cropTypeId) != 'undefined' ? (currentLocale === 'en' ? cropTypeList.find(item => item.value == cropTypeId).text : cropTypeList.find(item => item.value == cropTypeId).text_bn) : '' }}</span></b-th>
                                    <b-th v-if="!indx"><span class="capitalize">{{ typeof cropNameList.find(item => item.value == cropNameId) != 'undefined' ? (currentLocale === 'en' ? cropNameList.find(item => item.value == cropNameId).text : cropNameList.find(item => item.value == cropNameId).text_bn) : '' }}</span></b-th>
                                    <b-td v-if="!indx" class="text-right">{{ $n(cropNameInfo.stock_qty) }}</b-td>
                                    <b-td v-if="!indx" class="text-right">{{ $n(cropNameInfo.release_qty) }}</b-td>
                                    <b-td v-if="!indx" class="text-right">{{ $n(cropNameInfo.available_qty) }}</b-td>
                                  </b-tr>
                              </template>
                             <b-tr :key="cropTypeIndex + 200 + 9999">
                               <b-th class="text-right"><span class="capitalize">{{ $t('sitePreference.total') }}</span></b-th>
                               <b-td class="text-right" v-if="cropTypeTotalInfo[cropTypeId] && cropTypeTotalInfo[cropTypeId].release_qty">{{ $n(cropTypeTotalInfo[cropTypeId].stock_qty) }}</b-td>
                               <b-td class="text-right">{{ $n(cropTypeTotalInfo[cropTypeId].release_qty) }}</b-td>
                               <b-td class="text-right">{{ $n(cropTypeTotalInfo[cropTypeId].available_qty) }}</b-td>
                             </b-tr>
                           </template>
                        </b-tbody>
                        <b-tfoot>
                          <b-tr variant="secondary" >
                            <b-th colspan="3" class="text-right">
                              {{ $t('sitePreference.grossTotal') }}
                            </b-th>
                            <b-th class="text-right">{{ $n(totalStockQty) }}</b-th>
                            <b-th class="text-right">{{ $n(totalReleaseQty) }}</b-th>
                            <b-th class="text-right">{{ $n(totalAvailableQty) }}</b-th>
                          </b-tr>
                        </b-tfoot>
                      </b-table-simple>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, productStockReportApi } from '../../../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import excel from 'vue-excel-export'
import Vue from 'vue'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'
pdfMake.vfs = pdfFonts.pdfMake.vfs
Vue.use(excel)

const excelColumn = {
  SL: 'serial',
  'Crop Type': 'crop_type_custom',
  'Crop Name': 'crop_name_custom',
  'Stock Qty': 'stock_qty_custom',
  'Release Qty': 'release_qty_custom',
  'Available Qty': 'available_qty_custom'
}

const excelColumnBn = {
  'ক্রমিক নং': 'serial',
  'শস্যের ধরণ': 'crop_type_custom',
  'শস্য নাম': 'crop_name_custom',
  'স্টক পরিমাণ': 'stock_qty_custom',
  'রিলিজ পরিমাণ': 'release_qty_custom',
  'প্রাপ্য পরিমাণ': 'available_qty_custom'
}

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ListReportHead
  },
  name: 'UiDataTable',
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        slOffset: 1
      },
      showData: false,
      showHeading: false,
      search: {
        org_id: 13,
        farmer_id: 0,
        crop_type_id: 0,
        crop_id: 0
      },
      wareReportData: [],
      wareReportList: [],
      warehouseList: [],
      warehouseDetails: [],
      rows: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      loading: false,
      farmerListEn: [],
      farmerListBn: [],
      farmerDropDownList: [],
      cropTypeDropdownList: [],
      cropTypeTotalInfo: {},
      totalStockQty: 0,
      totalReleaseQty: 0,
      totalAvailableQty: 0,
      reportListData: [],
      cropNameDropdownList: [],
      cropNameDropdownListAll: []
    }
  },
  computed: {
    json_fields: function () {
      return this.currentLocale === 'bn' ? excelColumnBn : excelColumn
    },
    dataCustomizeExcel () {
      // var serial = 0
      const customizeData = this.reportListData.map(item => {
        if (this.currentLocale === 'bn') {
          item.crop_type_custom = item.crop_type_name_bn
          item.crop_name_custom = item.crop_name_bn
        } else {
          item.crop_type_custom = item.crop_type_name
          item.crop_name_custom = item.crop_name
        }
        item.serial = item.serial_no ? this.$n(item.serial_no) : ''
        item.stock_qty_custom = this.$n(item.stock_qty)
        item.release_qty_custom = this.$n(item.release_qty)
        item.available_qty_custom = this.$n(item.available_qty)
        return Object.assign({}, item)
      })
      const item = {}
      item.crop_name_custom = this.$t('sitePreference.grossTotal')
      item.stock_qty_custom = this.$n(this.totalStockQty)
      item.release_qty_custom = this.$n(this.totalReleaseQty)
      item.available_qty_custom = this.$n(this.totalAvailableQty)

      customizeData.push(item)

      return customizeData
    },
    cropTypeList: function () {
      return this.$store.state.ExternalUserIrrigation.warehouse.commodityGroupList.filter(item => item.status === 0)
    },
    cropNameList: function () {
      return this.$store.state.ExternalUserIrrigation.warehouse.commodityNameList.filter(item => item.status === 0)
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
    'search.crop_type_id': function (newVal, oldVal) {
      this.cropNameDropdownList = this.getCropNameList(newVal)
    }
  },
  created () {
    this.loadData()
    this.getFarmerList()
    this.getFarmerRequestList()
  },
  mounted () {
    core.index()
  },
  methods: {
    searchData () {
      // if (this.search.org_id !== 0 || this.search.division_id !== 0 || this.search.district_id !== 0 || this.search.upazilla_id !== 0) {
        this.loadData()
        this.showData = true
        this.showHeading = true
      // }
      // this.loading = false
    },
    getCropNameList (cropTypeId = null) {
      const cropNameList = this.$store.state.ExternalUserIrrigation.warehouse.commodityNameList.filter(item => item.status === 0)

      if (cropTypeId) {
        return cropNameList.filter(item => {
          if (item.commodity_group_id === cropTypeId && this.cropNameDropdownListAll.includes(item.value)) {
            return item
          }
        })
      }

      return cropNameList
    },
    isEmpty (obj) {
      return Object.keys(Object.keys(obj).length === 0 && obj.constructor === Object).length === 0 && Object.keys(obj).length === 0 && obj.constructor === Object.constructor === Object
    },
    async loadData () {
      this.loading = true
      await RestApi.getData(warehouseServiceBaseUrl, productStockReportApi, this.search).then(response => {
        const listData = []
        if (response.success) {
          setTimeout(() => {
          this.wareReportData = response.data
          const obj = response.data
          let totalStockQty = 0
          let totalReleaseQty = 0
          let totalAvailableQty = 0
          if (!this.isEmpty(obj)) {
            for (const cropTypeId in obj) {
              this.cropTypeTotalInfo[cropTypeId] = {
                stock_qty: 0,
                release_qty: 0,
                available_qty: 0
              }
            }
            let serial = 1
            for (const cropTypeId in obj) {
              if (Object.prototype.hasOwnProperty.call(obj, cropTypeId)) {
                const itemArr = obj[cropTypeId]
                const itemData = []
                let itemNo = 1
                let cropNameTotalStockQty = 0
                let cropNameTotalReleaseQty = 0
                let cropNameTotalAvailableQty = 0
                for (const cropNameId in itemArr) {
                  if (Object.prototype.hasOwnProperty.call(itemArr, cropNameId)) {
                    const nestedItem = itemArr[cropNameId]
                    const listItem = {}
                    listItem.serial_no = itemNo === 1 ? serial : ''

                    const cropTypeObj = this.cropTypeList.find(item => item.value === parseInt(cropTypeId))
                    if (typeof cropTypeObj !== 'undefined' && itemNo === 1) {
                      listItem.crop_type_name = cropTypeObj.text_en
                      listItem.crop_type_name_bn = cropTypeObj.text_bn
                    } else {
                      listItem.crop_type_name = ''
                      listItem.crop_type_name_bn = ''
                    }
                    const cropNameObj = this.cropNameList.find(item => item.value === parseInt(cropNameId))
                    if (typeof cropNameObj !== 'undefined') {
                      listItem.crop_name = cropNameObj.text_en
                      listItem.crop_name_bn = cropNameObj.text_bn
                    } else {
                      listItem.crop_name = ''
                      listItem.crop_name_bn = ''
                    }
                    itemData[cropNameId] = nestedItem
                    totalStockQty += nestedItem.stock_qty
                    cropNameTotalStockQty += nestedItem.stock_qty
                    totalReleaseQty += nestedItem.release_qty
                    cropNameTotalReleaseQty += nestedItem.release_qty
                    totalAvailableQty += nestedItem.available_qty
                    cropNameTotalAvailableQty += nestedItem.available_qty
                    listItem.stock_qty = nestedItem.stock_qty
                    listItem.release_qty = nestedItem.release_qty
                    listItem.available_qty = nestedItem.available_qty

                    listData.push(listItem)
                    itemNo++
                  }
                }
                this.cropTypeTotalInfo[cropTypeId] = {
                  stock_qty: cropNameTotalStockQty,
                  release_qty: cropNameTotalReleaseQty,
                  available_qty: cropNameTotalAvailableQty
                }
                const cropTypeTotalRowObj = {}
                cropTypeTotalRowObj.crop_name = 'Total'
                cropTypeTotalRowObj.crop_name_bn = 'মোট'
                cropTypeTotalRowObj.stock_qty = cropNameTotalStockQty
                cropTypeTotalRowObj.release_qty = cropNameTotalReleaseQty
                cropTypeTotalRowObj.available_qty = cropNameTotalAvailableQty

                listData.push(cropTypeTotalRowObj)
              }
              serial++
            }
            this.loading = false
          } else {
            this.loading = false
          }
          this.totalStockQty = totalStockQty
          this.totalReleaseQty = totalReleaseQty
          this.totalAvailableQty = totalAvailableQty
          this.reportListData = listData
          }, 1)
        } else {
          this.wareReportData = {}
          this.totalStockQty = 0
          this.totalReleaseQty = 0
          this.totalAvailableQty = 0
          this.loading = false
        }
      }).catch(error => {
        if (error) {
          //
        }
        this.wareReportList = []
        this.loading = false
      })
    },
    async getFarmerList () {
        const response = await RestApi.getData(authServiceBaseUrl, '/user/farmer-details')
        if (response.success) {
          const farmerList = response.data.filter(item => item.mobile_no === this.authUser.mobile_no)
          // farmerList.filter(item => {
          //   const newItem = {}
          //   newItem.value = item.mobile_no
          //   newItem.text = this.currentLocale === 'en' ? item.name : item.name_bn
          //   newItem.text_en = item.name
          //   newItem.text_bn = item.name_bn
          //
          //   this.farmerDropDownList.push(newItem)
          // })
          this.farmerListEn = farmerList.map(obj => {
            return { value: obj.mobile_no, text: obj.name }
          })
          this.farmerListBn = farmerList.map(obj => {
            return { value: obj.mobile_no, text: obj.name_bn }
          })
          this.search.farmer_id = this.authUser.mobile_no
        }
    },
    async getFarmerRequestList () {
      const response = await RestApi.getData(warehouseServiceBaseUrl, '/warehouse-service-farmer-req-entry/farmer-req-list/' + this.authUser.mobile_no)
      if (response.success) {
        const farReqEntryList = response.data
        if (farReqEntryList.length) {
          const cropTypeDropdownList = []
          const cropNameDropdownListAll = []
          farReqEntryList.forEach(item => {
            if (!cropTypeDropdownList.includes(item.crop_type_id)) {
              cropTypeDropdownList.push(item.crop_type_id)
            }
            if (!cropNameDropdownListAll.includes(item.crop_id)) {
              cropNameDropdownListAll.push(item.crop_id)
            }
          })
          this.cropTypeDropdownList = this.cropTypeList.filter(item => {
            if (cropTypeDropdownList.includes(item.value)) {
              return item
            }
          })
          this.cropNameDropdownListAll = cropNameDropdownListAll
        }
      }
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_type'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.crop_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.stockQty'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.releaseQty'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_report.availableQty'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Product Stock Report' : 'পণ্য স্টক প্রতিবেদন'
      const columnWids = ['7%', '20%', '20%', '15%', '15%', '15%']
      ExportPdf.exportPdf(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids)
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'crop_type_name' : 'crop_type_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'crop_name' : 'crop_name_bn' },
          { key: 'stock_qty' },
          { key: 'release_qty' },
          { key: 'available_qty' }
        ]
      // var serial = 0
      const listData = this.reportListData.map(item => {
        // serial += 1
        // Hello
        const rowData = keys.map((keyItem, index) => {
          if (keyItem.key === 'serial_no') {
              return { text: item[keyItem.key] ? this.$n(item[keyItem.key]) : '' }
          }
          if (['stock_qty', 'release_qty', 'available_qty'].includes(keyItem.key)) {
            return { text: this.$n(item[keyItem.key]) }
          }

          return { text: item[keyItem.key] }
        })
        return rowData
      })

      const totalItem = [
        { text: '' },
        { text: '' },
        this.currentLocale === 'en' ? { text: 'Gross Total :' } : { text: 'সর্ব মোট :' },
        { text: this.$n(this.totalStockQty) },
        { text: this.$n(this.totalReleaseQty) },
        { text: this.$n(this.totalAvailableQty) }
      ]
      listData.push(totalItem)

      return listData
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
